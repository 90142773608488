const siteHost = "https://enpaku-jdta.jp"; // without last slash.
const omekaHost = "";

module.exports = {
  // Site information
  siteHost,
  siteName: "JAPAN DIGITAL THEATRE ARCHIVES",
  siteTitle: "JDTA",
  description:
    "Japan Digital Theatre Archives (JDTA) は、緊急舞台芸術アーカイブ＋デジタルシアター化支援事業（EPAD）の一環として早稲田大学演劇博物館が開設した、現代演劇・舞踊・伝統芸能の三分野にわたる舞台公演映像の情報検索特設サイトです。",

  // head tag settings
  favicon: "/assets/img/favicon.svg",
  icon256: "/assets/img/icon.png",
  ogImage: "/assets/img/ogimage.jpg",
  // ogImageSmall: '/assets/img/ogimage_small.jpg', // サーバー側プログラムへ埋め込み
  fbAppId: "",
  og: true,
  twitter: true,
  fb: false,
  gtag: "G-VF0FLJN2RD",

  // API settings
  omekaHost,
  apiEndpoints: {
    theatricalResources:
      "https://c6x6nkkdulvgp4acg63tkkojeu0nyfkp.lambda-url.ap-northeast-1.on.aws/",
    stillImages:
      "https://j6m7h6up5esliqdfbivky55vd40mwezw.lambda-url.ap-northeast-1.on.aws/",
    itemResources:
      "https://4tis37z77e43ov63kzqeoqem6y0livip.lambda-url.ap-northeast-1.on.aws/",
    randomKeywords:
      "https://grytxogb2m6xdycpl2odoin2di0mbnht.lambda-url.ap-northeast-1.on.aws/",
    randomThumbnails:
      "https://k2haztmj47wctbd6ecuwlanzgu0bjcuf.lambda-url.ap-northeast-1.on.aws/",
    playtexts:
      "https://ygrd73mutlr33yntisuqm5aa6i0knmdn.lambda-url.ap-northeast-1.on.aws/",
    announcements:
      "https://45gxrfnvry7plot3a5dnpezl5u0nklfk.lambda-url.ap-northeast-1.on.aws/",
  },
};
